var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "title-left" }),
      _vm._v(_vm._s(_vm.dataList.insureMonth)),
    ]),
    _c("div", { staticClass: "tabs" }, [
      _c("p", [
        _vm._v("个人缴纳："),
        _c("span", [
          _vm._v(_vm._s(_vm._f("stateFormatNum")(_vm.dataList.personTotal))),
        ]),
        _vm._v(" 元"),
      ]),
      _c("p", [
        _vm._v("公司缴纳："),
        _c("span", [
          _vm._v(_vm._s(_vm._f("stateFormatNum")(_vm.dataList.companyTotal))),
        ]),
        _vm._v(" 元"),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "a-row",
          { staticClass: "table-head" },
          [
            _c("a-col", { attrs: { span: 2 } }),
            _vm._l(_vm.dataList.insureList, function (item, index) {
              return _c(
                "a-col",
                {
                  key: index,
                  attrs: { span: _vm.colWidth, title: item.insureName },
                },
                [_vm._v(" " + _vm._s(item.insureName) + " ")]
              )
            }),
          ],
          2
        ),
        _c(
          "a-row",
          { staticClass: "table-content" },
          [
            _c("a-col", { attrs: { span: 2, title: "本人缴纳" } }, [
              _vm._v(" 本人缴纳 "),
            ]),
            _vm._l(_vm.dataList.insureList, function (item, index) {
              return _c(
                "a-col",
                {
                  key: index,
                  attrs: { span: _vm.colWidth, title: item.personPay },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm._f("stateFormatNum")(item.personPay)) + " "
                  ),
                ]
              )
            }),
          ],
          2
        ),
        _c(
          "a-row",
          { staticClass: "table-content" },
          [
            _c("a-col", { attrs: { span: 2, title: "公司缴纳" } }, [
              _vm._v(" 公司缴纳 "),
            ]),
            _vm._l(_vm.dataList.insureList, function (item, index) {
              return _c(
                "a-col",
                {
                  key: index,
                  attrs: { span: _vm.colWidth, title: item.companyPay },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("stateFormatNum")(item.companyPay)) +
                      " "
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }